.grid-container {
  flex: 1; /* Makes the grid-container grow to occupy available space */
  display: flex;
  flex-direction: column;
}

.dx-datagrid {
  flex: 1;

  .dx-datagrid-headers {
    background-color: #3862ae;
    color: #fff;
    transition: all 0.4s ease;

    &.sticky-datagrid-header {
      position: sticky;
      top: 87px;
      z-index: 1000;
      box-shadow: 0px 4px 8px rgb(0, 0, 0, 0.2);
    }

    .dx-datagrid-table {
      .dx-row > td {
        padding: 10px;
        transition: background-color 200ms ease-in-out;

        &:hover:not(.dx-command-select):not(.dx-command-expand):not(.dx-editor-cell):not(.dx-command-edit):not(
            .dx-datagrid-group-space
          ) {
          background-color: #4773c4;

          .dx-datagrid-text-content {
            color: #fff;
          }
        }

        &[aria-sort='ascending'],
        &[aria-sort='descending'] {
          background-color: #4773c4;
        }

        .dx-sort-indicator,
        .dx-sort {
          color: #fff;
        }
      }
    }
  }
}

.dx-datagrid .dx-row-alt:not(.is-external) > td {
  background-color: #f3f3f3;
}

/* If .is-external is present*/
.dx-datagrid .dx-data-row.is-external:not(.is-invalid) > td:not(.dx-cell-modified),
.dx-datagrid .dx-data-row-alt.is-external:not(.is-invalid) > td:not(.dx-cell-modified) {
  color: #aaaaaaaa;
  font-style: italic;
}

// selected row
.dx-datagrid-rowsview .dx-data-row[aria-selected='true']:not(.is-external) > td:not(.dx-focused):not(.dx-cell-modified),
.dx-datagrid-rowsview .dx-row-focused.dx-data-row .dx-command-edit .dx-link {
  // different color ?
  background-color: #d5e4ff;
  border-bottom-color: #e0e0e0;
  border-bottom-width: 1px;
  color: unset;
}

.dx-datagrid-rowsview .dx-row-focused.dx-data-row > td:not(.dx-focused):not(.dx-cell-modified),
.dx-datagrid-rowsview .dx-row-focused.dx-data-row .dx-command-edit .dx-link {
  background-color: #d5e4ff;
  border-bottom-color: #e0e0e0;
  border-bottom-width: 1px;
  color: unset;
}

.dx-datagrid-table .dx-row-removed > td,
.dx-datagrid-rowsview .dx-row-focused.dx-data-row.dx-row-removed > td:not(.dx-focused):not(.dx-cell-modified),
.dx-datagrid-rowsview .dx-row-focused.dx-data-row.dx-row-removed .dx-command-edit .dx-link {
  background-color: #ffc6cc;
}

.dx-datagrid-rowsview
  .dx-row-focused.dx-data-row
  > td:not(.dx-focused):not(.dx-cell-modified)
  .dx-datagrid-group-opened,
.dx-datagrid-rowsview
  .dx-row-focused.dx-data-row
  > td:not(.dx-focused):not(.dx-cell-modified)
  .dx-datagrid-group-closed,
.dx-datagrid-rowsview .dx-row-focused.dx-data-row .dx-command-edit .dx-link .dx-datagrid-group-opened,
.dx-datagrid-rowsview .dx-row-focused.dx-data-row .dx-command-edit .dx-link .dx-datagrid-group-closed {
  color: rgba(98, 98, 98, 0.54);
}

.dx-datagrid-header-panel {
  margin-bottom: 8px;
  padding: 0 6px;

  .dx-texteditor {
    border-radius: 6px;

    &.dx-state-focused {
      &::before {
        border-bottom-color: #53b7e8;
      }
    }
  }
}

.dx-pager .dx-pages .dx-selection,
.dx-pager .dx-page-sizes .dx-selection {
  background-color: #53b7e8;
}

.datagrid-container {
  max-height: 440px;
}

.grid-cuts .dx-scrollbar-vertical.dx-scrollbar-hoverable,
.grid-cuts .dx-scrollbar-vertical.dx-scrollbar-hoverable.dx-state-hover .dx-scrollable-scroll,
.grid-cuts .dx-scrollbar-vertical.dx-scrollbar-hoverable.dx-scrollable-scrollbar-active .dx-scrollable-scroll,
.grid-cuts .dx-scrollbar-vertical .dx-scrollable-scroll {
  width: 6px;
}

.grid-cuts .dx-scrollbar-vertical.dx-scrollbar-hoverable .dx-scrollable-scroll-content {
  background-color: rgba(56, 98, 174, 0.75);
}

.grid-cuts .dx-scrollable-scroll-content {
  background-color: rgba(56, 98, 174, 0.75);
  padding: 0;
  opacity: 1;
}

.grid-cuts .dx-scrollable-scroll.dx-state-invisible {
  display: block !important;
  padding: 0;
  opacity: 1;
}

.grid-cuts .dx-scrollable-scroll {
  padding: 0;
}
