.user-account {
    font-size: .85rem;
    margin-right: 2px;
    text-align: right;

    @media (min-width: 1200px) {
        margin-right: 8px;
    }

    &-intro {
        margin-top: .125rem;
    }

    &-name,
    &-date {
        line-height: 1.25em;
    }

    button {
        color: #ffc20e;
        min-width: auto;
        padding: 0;
        text-transform: capitalize;

        &:hover {
            background-color: transparent;
            color: #ffd34f;
        }
    }
}