.note {
  margin: 1em;
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.5));
}

.note-body {
  --edge: 0.2rem;

  width: fit-content;
  padding: 1rem;
  color: rgba(0, 0, 0, 0.8);
  position: relative;
  isolation: isolate;
  clip-path: inset(0rem 0rem round 0rem 2rem 2rem 2rem);
}

.note-body::before {
  content: '';
  position: absolute;
  inset: 0;
  background-color: white;
  z-index: -2;
}

.note-body::after {
  content: '';
  position: absolute;
  inset: 0;
  z-index: -1;
  clip-path: inset(
    var(--edge) var(--edge) round 0rem calc(2rem - var(--edge)) calc(2rem - var(--edge)) calc(2rem - var(--edge))
  );
}
