.profile-nav {
    align-items: center;
    display: flex;

    &-trigger {
        button {
            padding: 0;
        
            &:hover {
                background-color: transparent;
            }
        }

        svg {
            border: 2px solid transparent;
            border-radius: 50%;
            color: #fff;
            font-size: 48px;
            pointer-events: none;
    
            .nav-open & {
                border-color: #ffc20e;
            }
        }
    }

    &-menu {
        background: #fff;
        border: 1px solid #ccc;
        border-color: rgba(33,37,41,.2);
        border-radius: 2px;
        box-shadow: 0 2px 5px rgba(33,37,41,.2);
        display: flex;
        font-size: 15px;
        max-width: 525px;
        min-width: 300px;
        padding: 10px 12px;
        position: absolute;
        right: 6px;
        top: 60px;
        z-index: 10;

        button {
            color: #212121;
            flex-direction: column;
            font-size: .95rem;
            height: 75px;
            padding: 0;
            text-transform: capitalize;
            width: 116px;
        }

        svg {
            color: #53b7e8;
            font-size: 35px;
        }
    }

    &-link {
        align-items: center;
        background-color: #f3f3f3;
        border: 1px solid rgba(0, 0, 0, 0.12);
        display: flex;
        justify-content: center;
        margin: 0;

        &:not(:last-child) {
            margin-right: 10px;
        }
    }
}