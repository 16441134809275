.breadcrumbs {
    margin: 0 0 1.25rem;
    position: relative;
    z-index: 9;

    &-link {
        color: rgba(24, 24, 24, 0.65);
        text-decoration: none;
        text-transform: capitalize;

        &:hover {
            text-decoration: underline;
        }
    }

    &-text {
        color: rgba(24, 24, 24, 0.9);
        font-weight: 500;
        text-transform: capitalize;
    }

    .MuiBreadcrumbs-separator {
        margin-left: 4px;
        margin-right: 4px;
    }
}