.ptJob {
  border-bottom: 2px solid black;
  margin: 2rem 4rem;
}

.subMenu {
  background-color: #0076d2;
  margin-top: 90px;
  @media (min-width: 900px) {
    margin-top: 76px;
  }
}
