h1 {
    &.MuiTypography-root {
        color: #3862ae;
        font-size: 1.5rem;
        font-weight: 600;
        margin: 0;   

        span {
            font-weight: 400;
        }
    }
}

h2 {
    &.MuiTypography-root {
        color: rgba(0,0,0,.87);
        font-size: 1.125rem;
        font-weight: 600;
        margin: 0 0 1.125rem;   
    }
}

h6 {
    &.MuiTypography-root {
        color: rgba(0,0,0,.87);
        font-size: 1rem;
        font-weight: 600;
        margin: 0 0 1.125rem;   
    }
}