.module {
  display: flex;
  height: 125px;
  text-decoration: none;

  &-grid {
    height: 308px;
    padding: 1rem;
    overflow-y: auto;
  }

  button {
    background-color: #f3f3f3;
    border: 1px solid rgba(0, 0, 0, 0.12);
    flex-direction: column;
    height: 100%;
    padding: 1rem;
    width: 100%;

    &:hover {
      background-color: #f3f7ff;
    }

    .module-icon {
      color: #53b7e8;
      font-size: 50px;
    }

    .module-title {
      color: #212121;
      font-size: 0.95rem;
      line-height: 1em;
      margin-top: 7px;
      text-transform: capitalize;
    }
  }
}

.module-pt {
  @extend .module;
  &-grid {
    padding: 1rem;
    overflow-y: auto;
  }
}
