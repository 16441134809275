::-webkit-scrollbar {
  background-color: transparent;
  height: 6px;
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

.spacer {
  border-right: 1px solid rgba(255, 255, 255, 0.6);
  margin: 0 7px;
}

.MuiFormLabel-asterisk {
  color: red;
}


.dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused):not(.dx-row-removed) > td {
  background-color: #d5e4ff;
  color: unset;
}