.subhdr {
    position: relative;

    a {
        color: #212121;
        font-size: .95rem;
        text-decoration: none;
        transition: color 200ms ease-in-out;

        &:hover {
            color: #000;
        }

        &.link-more {
            margin: 0 auto;
    
            @media (min-width: 900px) {
                margin: 0;
            }

            .link-trigger-icon {
                margin-right: 5px;
            }
        }
    }

    .link-trigger {
        align-items: center;
        display: flex;
        font-weight: 600;
        pointer-events: none;
    }

    > a:not(.link-more) {
        display: none;

        @media (min-width: 900px) {
            display: block;
        }
    }
}

.subnav {
    background-color: #dcddde;
    border-top: 1px solid #a3a3a3;
    padding: 1.125rem 1.5rem;
    position: absolute;
    right: 0;
    text-align: left;
    top: 40px;
    width: 100%;
    z-index: 10;

    @media (min-width: 900px) {
        border-top: none;
        padding: 1rem 2.125rem;
        right: 1px;
        text-align: right;
        width: auto;
    }

    a {
        display: block;

        &:not(:last-child) {
            margin-bottom: 1rem;
        }
    }

    &-icon {
        cursor: pointer;
        font-size: 1.5rem;
        position: absolute;
        right: 5px;
        top: 8px;
    }

    .mbl-only {
        display: block;

        @media (min-width: 900px) {
            display: none;
        }       
    }
}