#muiForm {
    .Mui-checked,
    .Mui-focused {
        color: #53b7e8;
    }

    .Mui-focused fieldset {
        border-color: #53b7e8;
    }

    .Mui-disabled {
        color: rgba(0, 0, 0, 0.26);
    }

    .label-radio {
        color: hsla(0, 0%, 0%, 0.87);
        font-weight: 500;
    }

    a {
        color: #3862ae;
    }
}

.MuiButtonBase-root.MuiCheckbox-root {
    &.Mui-checked {
        color: #53b7e8;

        &.check-blue {
            color: #3862ae;
        }
    }

    &.Mui-disabled{
        color: rgba(0, 0, 0, 0.26);
    }   
}

#search {
    .MuiFilledInput-underline:after {
        border-bottom-color: #53b7e8;
    }
}

#search-grid {
    padding: .5rem .5rem .5rem 0;
}

.search-icon {
    margin-top: 0 !important;
}

.MuiAutocomplete-popupIndicator {
    color: #fff !important;
}

.MuiAutocomplete-clearIndicator {
    color: #3862ae !important;
}

.auto-select .MuiAutocomplete-popupIndicator {
    color: #757575 !important;
}