.hdr {
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 12;

  &-main {
    background-color: #052668;
    color: #fff;
    justify-content: space-between;
    padding: 1rem 1.25rem;

    @media (min-width: 900px) {
      padding: .95rem 1rem;
    }
  }

  &-col {
    align-items: center;
    display: flex;

    &:first-child {
      margin-bottom: 6px;

      @media (min-width: 900px) {
        margin-bottom: 0;
      }
    }

    &:last-child {
      display: none;
      justify-content: flex-end;
      position: relative;

      @media (min-width: 900px) {
        display: flex;
      }
    }

    a {
      color: #fff;
      text-decoration: none;
    }
  }

  &-links {
    align-items: center;
    display: flex;

    span {
      transition: opacity 200ms ease-in-out;
    }

    &:hover {
      span {
        opacity: 0.9;
      }
    }
  }

  &-icon {
    margin-right: 5px;
  }
}
