.MuiButton-root {
  &.btn {
    background-color: #53b7e8;
    border-radius: 6px;
    color: #fff;
    padding: 6px 15px;

    &:hover {
      background-color: #65c8ef;
    }

    &.btn-active {
      background-color: #1976d2;
    }

    &.btn-blue {
      background-color: #3862ae;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
      &:hover {
        background-color: #4571c2;
      }
    }

    &.btn-cancel {
      background-color: #d7d7d7;
      color: rgba(0, 0, 0, 0.65);
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
      margin-right: 8px;

      &:hover {
        background-color: #ebe8e8;
      }
    }

    &.btn-icon {
      > span {
        margin: -1px 6px 0 0;
      }
    }

    &:disabled {
      background-color: rgb(230, 230, 230);
      color: #aaa;
    }
  }

  &.btn-step {
    background-color: transparent;
    color: #3862ae;
    padding: 6px 0;

    &.btn-next {
      span {
        margin-left: 1px;
      }
    }

    &.btn-prev {
      span {
        margin-right: 1px;
      }
    }

    &:hover {
      background-color: transparent;
      color: #426ebe;
    }
  }
}

.MuiSvgIcon-root.MuiStepIcon-root {
  &.Mui-active {
    color: #3862ae;
  }
}

.dx-datagrid
  .dx-datagrid-content
  .dx-datagrid-table
  .dx-row
  > td:not(.dx-validation-pending):not(.dx-datagrid-select-all).dx-command-edit.dx-command-edit-with-icons
  .dx-link {
  background-size: 22px 22px;
  font-size: 22px;
  height: 22px;
  line-height: 22px;
  width: 22px;
}

.dx-datagrid-rowsview .dx-row-focused.dx-data-row .dx-command-edit .dx-link:focus {
  background-color: transparent;
  color: inherit;
}

.dx-datagrid .dx-link.dx-link-delete,
.dx-datagrid-rowsview .dx-selection:hover > td .dx-link.dx-link-delete,
.dx-datagrid-rowsview .dx-selection > td .dx-link.dx-link-delete,
.dx-datagrid-rowsview .dx-row-focused.dx-data-row .dx-command-edit .dx-link.dx-link-delete {
  color: #f4032b;
}

.dx-datagrid .dx-link.dx-link-undelete,
.dx-datagrid-rowsview .dx-selection:hover > td .dx-link.dx-link-undelete,
.dx-datagrid-rowsview .dx-selection > td .dx-link.dx-link-undelete,
.dx-datagrid-rowsview .dx-row-focused.dx-data-row .dx-command-edit .dx-link.dx-link-undelete {
  color: #0d9958;
}
